import { useRouter } from "next/router";
import { useMemo } from "react";

import { DateRange } from "@/components/Utils/SelectOptions";
import { cleanupQueryParams } from "@/utils/filterHelpers";

export const DATE_RANGE_QUERY_PARAM_NAME = "date_range";

const defaultDateRange: DateRange = "last_7_days";

export const useDateRangeQueryParam = () => {
  const router = useRouter();

  return useMemo(() => {
    const { [DATE_RANGE_QUERY_PARAM_NAME]: dateRangeQueryParam } = router.query;

    const dateRange = (dateRangeQueryParam as DateRange | undefined) || defaultDateRange;

    function updateDateRange(newDateRange: DateRange, useReplaceForRouter?: boolean) {
      const combinedQuery = cleanupQueryParams({ ...router.query, date_range: newDateRange });
      if (useReplaceForRouter) {
        router.replace({ query: combinedQuery }, undefined, { scroll: false });
      } else {
        router.push({ query: combinedQuery }, undefined, { scroll: false });
      }
    }

    return { dateRange, updateDateRange };
  }, [router]);
};
