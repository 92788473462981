import { Button } from "@fonoa/ui-components/button";
import { GlobeIcon, ListIcon } from "@fonoa/ui-components/icons";
import { Typography } from "@fonoa/ui-components/typography";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { USThresholdList } from "@/features/Tax/DashboardPage/List";
import { USThresholdMap } from "@/features/Tax/DashboardPage/Map";
import {
  NexusGroup,
  NexusStatus,
  StateInfo,
  statusToLightColor,
  statusToText,
} from "@/features/Tax/DashboardPage/nexus.types";
import { useThresholdOps } from "@/hooks/useThresholdLabels";
import { MonthlyAggregate } from "@/server/trpc/routers/tax/aggregates";
import { USThresholds } from "@/server/trpc/routers/tax/thresholds";
import { trpc } from "@/utils/trpc";

interface MonitoringProps {
  view: "map" | "list";
  setView: (view: "map" | "list") => void;
  isLoading: boolean;
  states: StateInfo[];
}

interface NexusSectionProps {
  view: "map" | "list";
  isLoading: boolean;
  states: StateInfo[];
}

function toStatusGroups(states: StateInfo[]) {
  const groups = new Map<NexusStatus, NexusGroup>();
  states.forEach((s) => {
    const group = groups.get(s.status) || {
      status: s.status,
      count: 0,
    };
    group.count += 1;
    groups.set(s.status, group);
  });
  return [...groups.values()];
}

function NexusRegionsSection({ view, isLoading, states }: NexusSectionProps) {
  const stateMap = useMemo(() => {
    const _stateMap = {} as { [s: string]: StateInfo };
    states.forEach((s) => (_stateMap[s.fips] = s));
    return _stateMap;
  }, [states]);

  const statusGroups = useMemo(() => toStatusGroups(states), [states]);
  const statusGroupsKey = statusGroups.map((g) => (
    <div key={g.status} className="flex items-center">
      <div className={`bg-${statusToLightColor(g.status)} mx-2 h-2 w-2 rounded-full`} />
      <Typography component="p" variant="small">
        {statusToText(g.status)} ({g.count})
      </Typography>
    </div>
  ));

  return view === "map" ? (
    <>
      <div className="px-20 py-12">
        <USThresholdMap states={stateMap} />
      </div>
      <div className="flex w-full items-center justify-start pt-4">{statusGroupsKey}</div>
    </>
  ) : (
    <USThresholdList isLoading={isLoading} data={states} />
  );
}

export function formatThresholds(usThresholds?: USThresholds[]): USThresholds[] | undefined {
  return usThresholds?.map((t) => ({
    ...t,
    thresholds: t.thresholds.map((_t) => ({ ..._t, effectiveFrom: new Date(_t.effectiveFrom) })),
  }));
}

export function formatAggregates(
  aggregates?: MonthlyAggregate<Date>[]
): MonthlyAggregate<Date>[] | undefined {
  return aggregates?.map((a) => ({
    ...a,
    month: new Date(a.month),
  }));
}

export function NexusMonitoringPanel({ view, setView, states, isLoading }: MonitoringProps) {
  return (
    <>
      <div className="mt-4 w-full rounded border border-blueGray200 px-8 pt-5 pb-4">
        <div className="flex flex-col ">
          <div className="flex w-full justify-between">
            <div className="flex text-left">
              <Typography component="h5" fontWeight="font-medium">
                <FormattedMessage defaultMessage="Nexus Monitoring" id="mtCUXt" />
              </Typography>
            </div>
            <div className="flex text-right">
              <Button
                size="REGULAR"
                variant="LINK"
                leftIcon={GlobeIcon}
                onClick={() => {
                  setView("map");
                }}
              >
                <FormattedMessage defaultMessage="Map" id="zuqMfZ" />
              </Button>
              <div className="px-2" />
              <Button
                size="REGULAR"
                variant="LINK"
                leftIcon={ListIcon}
                onClick={() => {
                  setView("list");
                }}
              >
                <FormattedMessage defaultMessage="List" id="nOk9mh" />
              </Button>
            </div>
          </div>
          <NexusRegionsSection view={view} isLoading={isLoading} states={states} />
        </div>
      </div>
    </>
  );
}

export function NexusMonitoringPanelConnected() {
  const [view, setView] = useState<"map" | "list">("map");
  const tOps = useThresholdOps();
  const query = useMemo(() => ({ country: "us" }), []);

  const { data: aggregates, isLoading: isLoadingA } = trpc.tax.getMonthlyAggregates.useQuery(
    query,
    { select: formatAggregates }
  );
  const { data: registrations, isLoading: isLoadingR } = trpc.tax.getRegistrations.useQuery();
  const { data: thresholds, isLoading: isLoadingT } = trpc.tax.getUsThresholds.useQuery(undefined, {
    select: formatThresholds,
  });

  const allStates: StateInfo[] = useMemo(() => {
    if (!aggregates || !registrations || !thresholds) return [];

    const isRegisteredInRegion = (region: string | undefined) => {
      return registrations.some(
        (reg) => reg.region === region && (!reg.end_date || new Date(reg.end_date) >= new Date())
      );
    };

    const states = thresholds.map((t) => t.fips);
    return states.map((fips) => {
      const threshold = tOps.findThreshold(thresholds, fips);
      const registered = isRegisteredInRegion(fips);
      const months = aggregates.filter((a) => a.region === fips);

      const [cMonths, ...pastPeriods] = tOps.groupByEvaluationPeriod(months, threshold);
      const revenue = cMonths.map((a) => a.revenue?.total || 0).reduce((t, c) => t + c, 0);
      const transactions = cMonths.map((a) => a.count?.total || 0).reduce((t, c) => t + c, 0);

      function findStatus(amount: number, limit?: number): NexusStatus {
        if (registered) return "REGISTERED";

        const fraction = amount / (limit ?? Number.POSITIVE_INFINITY);
        if (fraction > 1) return "THRESHOLD_EXCEEDED";
        if (fraction > 0.75) return "APPROACHING_THRESHOLD";
        if (fraction > 0) return "TO_MONITOR";
        return "NO_ACTIVITY";
      }

      function findOverallStatus(rStatus: NexusStatus, cStatus: NexusStatus): NexusStatus {
        if (registered) return "REGISTERED";
        const previouslyExceeded = pastPeriods?.some((pMonths) => {
          const revenue = pMonths.map((a) => a.revenue?.total || 0).reduce((t, c) => t + c, 0);
          const transactions = pMonths.map((a) => a.count?.total || 0).reduce((t, c) => t + c, 0);
          return (
            revenue > (threshold?.revenueLimit ?? Number.POSITIVE_INFINITY) ||
            transactions > (threshold?.transactionLimit ?? Number.POSITIVE_INFINITY)
          );
        });
        if (previouslyExceeded) return "THRESHOLD_EXCEEDED";
        const statuses = [rStatus, cStatus];
        if (statuses.includes("THRESHOLD_EXCEEDED")) return "THRESHOLD_EXCEEDED";
        if (statuses.includes("APPROACHING_THRESHOLD")) return "APPROACHING_THRESHOLD";
        if (statuses.includes("TO_MONITOR")) return "TO_MONITOR";
        return "NO_ACTIVITY";
      }

      const revenueStatus = findStatus(revenue, threshold?.revenueLimit);
      const transactionStatus = findStatus(transactions, threshold?.transactionLimit);
      return {
        fips,
        status: findOverallStatus(revenueStatus, transactionStatus),
        revenue,
        thresholdDescription: tOps.getDescription(threshold),
        revenueThreshold: threshold?.revenueLimit,
        revenueStatus,
        transactions,
        transactionThreshold: threshold?.transactionLimit,
        transactionStatus,
      } as StateInfo;
    });
  }, [tOps, aggregates, registrations, thresholds]);

  const isLoading = useMemo(() => {
    return isLoadingA || isLoadingR || isLoadingT;
  }, [isLoadingA, isLoadingR, isLoadingT]);

  return (
    <NexusMonitoringPanel view={view} setView={setView} states={allStates} isLoading={isLoading} />
  );
}
