import { ColumnAlign, Table, TableColumn } from "@fonoa/ui-components/table";
import { Typography } from "@fonoa/ui-components/typography";
import { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import {
  getDefaultFilter,
  ReportingDashboardFilterProps,
  ReportingDashboardFilters,
} from "@/features/E-Invoicing/DashboardPage/components/ReportingDashboardFilters/ReportingDashboardFilters";
import {
  RevenueBreakdownItem,
  RevenueBreakdownResponse,
} from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { trpc } from "@/utils/trpc";

type TotalRevenueProps = {
  loading: boolean;
  error: boolean;
  values?: RevenueBreakdownResponse;
  onFilterChange(selectedFilter: ReportingDashboardFilterProps): void;
  oldValues: ReportingDashboardFilterProps;
};

export const ReportingRevenuesPanel = ({
  loading,
  error,
  values,
  onFilterChange,
  oldValues,
}: TotalRevenueProps) => {
  const data = values?.data;
  const columns: TableColumn<RevenueBreakdownItem>[] = [
    {
      Header: "Currency",
      accessor: "currency_code",
      disableSortBy: true,
      noWrap: true,
      headClassName: "text-xs",
      className: "text-xs text-blueGray900",
    },
    {
      Header: "Amount",
      accessor: "total_amount",
      disableSortBy: true,
      noWrap: true,
      align: ColumnAlign.RIGHT,
      headClassName: "text-xs",
      Cell: (cell) => (
        <Typography variant="h6" color="text-blueGray900">
          <FormattedNumber value={cell.value} maximumFractionDigits={2} />
        </Typography>
      ),
    },
  ];

  return (
    <>
      <div className="mt-4 w-full rounded border border-blueGray200 px-8 py-5">
        <div className="mb-1 flex">
          <Typography
            variant="h5"
            fontWeight="font-medium"
            color="text-blueGray900"
            spacing={{ mr: 2 }}
          >
            <FormattedMessage defaultMessage="Total revenue" id="pQvioy" />
          </Typography>
          <div className="hidden">
            <ReportingDashboardFilters onChange={onFilterChange} values={oldValues} />
          </div>
        </div>
        {error ? (
          <div className="flex h-full w-full items-center justify-center">
            <Typography variant="h6" color="text-blueGray900">
              <FormattedMessage
                defaultMessage="Something went wrong loading the total revenue data. Please try again later or contact us."
                id="UzEzU0"
              />
            </Typography>
          </div>
        ) : (
          <Table
            className="mt-4"
            loading={loading}
            loadingRowLimit={2}
            emptyImage={<></>}
            emptyHeightAsRow
            columns={columns}
            data={data}
            narrowRows
          />
        )}
      </div>
    </>
  );
};

export const ReportingRevenuesPanelConnected = () => {
  const [filters, setFilters] = useState<ReportingDashboardFilterProps>(getDefaultFilter());

  const {
    data: revenueBreakdown,
    isLoading: revenueBreakdownLoading,
    isError: revenueBreakdownError,
  } = trpc.eInvoicing.revenueBreakdown.useQuery({
    dateFrom: filters.dateFrom?.toISOString(),
    dateTo: filters.dateTo?.toISOString(),
  });

  return (
    <ReportingRevenuesPanel
      loading={revenueBreakdownLoading}
      error={revenueBreakdownError}
      values={revenueBreakdown}
      onFilterChange={setFilters}
      oldValues={filters}
    />
  );
};
