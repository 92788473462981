import { components } from "@fonoa/returns/types";
import { useRouter } from "next/router";

import { SummaryPanel } from "@/features/ReturnsDashboard/components/Summary/SummaryPanel";
import { useReturnSummaryData } from "@/features/ReturnsDashboard/ReturnsDashboard.api";
import { routes } from "@/lib/routes";

type SummaryPanelProps = {
  data?: components["schemas"]["ReturnsSummaryResponse"]["data"];
  error?: boolean;
  loading?: boolean;
  onOverdueViewClick?: () => void;
};

export function ReturnsSummaryPanel({
  data,
  loading,
  error,
  onOverdueViewClick,
}: SummaryPanelProps) {
  return (
    <SummaryPanel
      data={data}
      loading={loading}
      error={error}
      onOverdueViewClick={onOverdueViewClick}
    />
  );
}

export const ReturnsSummaryPanelConnected = () => {
  const summary = useReturnSummaryData();
  const router = useRouter();

  return (
    <ReturnsSummaryPanel
      {...summary.data}
      error={summary.isError}
      loading={summary.isLoading}
      onOverdueViewClick={() =>
        router.push(routes.returnsFiledReturns, undefined, { shallow: true })
      }
    />
  );
};
