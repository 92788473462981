import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

import {
  AlertCount,
  AlertType,
} from "@/features/E-Invoicing/DashboardPage/components/AlertsSection/AlertsSection.utils";
import type {
  DashboardAlert,
  DashboardAlertsResponse,
} from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { Loader } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.utils";
import { trpc } from "@/utils/trpc";

export interface AlertsProps {
  alerts?: DashboardAlertsResponse;
  alertsLoading: boolean;
  alertsError: boolean;
}

const AlertRow = ({ type, count }: DashboardAlert) => {
  return (
    <div className="py-6">
      <Typography variant="p" color="text-blueGray900" fontSize="text-sm">
        <AlertType type={type} />
      </Typography>
      <Typography variant="p" color="text-blueGray900" fontSize="text-sm" spacing={{ mt: 1 }}>
        <AlertCount type={type} count={count} />
      </Typography>
    </div>
  );
};

export const ReportingAlertsPanel = ({ alerts, alertsLoading, alertsError }: AlertsProps) => {
  const validAlerts = (alerts?.data || []).filter((alert) => alert.count > 0);

  return (
    <div>
      <div>
        {alertsError ? (
          <Typography variant="small" color="text-blueGray900" spacing={{ mt: 2.5 }}>
            <FormattedMessage defaultMessage="No Reporting alerts available" id="rD/YjQ" />
          </Typography>
        ) : !alertsLoading && validAlerts.length === 0 ? (
          <></>
        ) : (
          <Loader size="LARGE" isLoading={alertsLoading}>
            {validAlerts.map((alert, i) => (
              <AlertRow key={`alert-${alert.type}-${i}`} {...alert} />
            ))}
          </Loader>
        )}
      </div>
    </div>
  );
};

export const ReportingAlertsPanelConnected = () => {
  const {
    data: alerts,
    isLoading: alertsLoading,
    isError: alertsError,
  } = trpc.reporting.dashboardAlerts.useQuery();

  return (
    <ReportingAlertsPanel alerts={alerts} alertsLoading={alertsLoading} alertsError={alertsError} />
  );
};
