import moment from "moment";

import { DateRange } from "@/components/Utils/SelectOptions";
import { getInvalidTinCount } from "@/features/Lookup/LookupPage.utils";
import { ValidationStats } from "@/features/Lookup/ValidationHistory/components/ValidationHistorySummary/ValidationHistorySummary";
import {
  GetStatsResponse,
  StatsResult,
} from "@/features/Lookup/ValidationHistory/ValidationHistory.types";
import { ThresholdState } from "@/hooks/lookup/useSubscriptionStats";

export function combineData(data: GetStatsResponse["stats"]) {
  const grouped = data?.reduce((prev, curr) => {
    const date = moment(curr.date).format("MMM");

    if (!prev[date]) {
      prev[date] = [];
    }

    prev[date].push(curr);

    return prev;
  }, {} as Record<string, StatsResult[]>);

  if (!grouped) return [];

  return Object.values(grouped)?.map((group) => {
    return group.reduce(
      (prev, curr) => {
        return {
          valid_format_and_checksum_count:
            prev.valid_format_and_checksum_count + curr.valid_format_and_checksum_count,
          invalid_format_or_checksum_count:
            prev.invalid_format_or_checksum_count +
            getInvalidTinCount({
              totalCount: curr.total_count,
              validFormatAndChecksumCount: curr.valid_format_and_checksum_count,
            }),
          date: curr.date,
        };
      },
      {
        valid_format_and_checksum_count: 0,
        invalid_format_or_checksum_count: 0,
        date: "",
      }
    );
  });
}

const mapToValidInvalid = (data: GetStatsResponse["stats"]) =>
  data.map(({ valid_format_and_checksum_count, total_count, date }) => ({
    date,
    valid_format_and_checksum_count,
    invalid_format_or_checksum_count: getInvalidTinCount({
      totalCount: total_count,
      validFormatAndChecksumCount: valid_format_and_checksum_count,
    }),
  }));

export function parseChartData(stats?: GetStatsResponse["stats"], dateRange?: DateRange) {
  if (!stats) {
    return [
      { id: "valid", data: [] },
      { id: "invalid", data: [] },
    ];
  }

  const applicableDateRanges: DateRange[] = ["last_3_months", "last_6_months", "last_12_months"];
  const data =
    dateRange && applicableDateRanges.includes(dateRange)
      ? combineData(stats)
      : mapToValidInvalid(stats);

  return [
    {
      id: "valid",
      data: data.map(({ valid_format_and_checksum_count, date }) => ({
        x: date,
        y: valid_format_and_checksum_count,
      })),
    },
    {
      id: "invalid",
      data: data.map(({ invalid_format_or_checksum_count, date }) => ({
        x: date,
        y: invalid_format_or_checksum_count,
      })),
    },
  ];
}

export function isSafeThreshold(thresholdState: ThresholdState | undefined) {
  return !thresholdState || thresholdState === "safe";
}

export function buildValidationStatsFromHistory(
  nullableHistoryStats: StatsResult[] | undefined
): ValidationStats {
  if (!nullableHistoryStats) {
    return EMPTY_STATS;
  }

  return nullableHistoryStats.reduce(
    (prev, curr) => ({
      totalCount: prev.totalCount + curr.total_count,
      validCount: prev.validCount + curr.valid_format_and_checksum_count,
      invalidCount:
        prev.invalidCount +
        getInvalidTinCount({
          totalCount: curr.total_count,
          validFormatAndChecksumCount: curr.valid_format_and_checksum_count,
        }),
    }),
    EMPTY_STATS
  );
}

export const EMPTY_STATS: ValidationStats = {
  totalCount: 0,
  validCount: 0,
  invalidCount: 0,
};
