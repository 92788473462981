import { useState } from "react";

import {
  getDefaultFilter,
  ReportingDashboardFilterProps,
  ReportingDashboardFilters,
} from "@/features/E-Invoicing/DashboardPage/components/ReportingDashboardFilters/ReportingDashboardFilters";
import {
  ErrorBreakdownProps,
  ReportingErrorBreakdownSection,
} from "@/features/E-Invoicing/DashboardPage/components/ReportingErrorBreakdownSection/ReportingErrorBreakdownSection";
import {
  ReportingRateChart,
  ReportingRateProps,
} from "@/features/E-Invoicing/DashboardPage/components/ReportingRateChart/ReportingRateChart";
import { noop } from "@/lib/utils";
import { trpc } from "@/utils/trpc";

interface ReportingRatesPanelProps {
  onFilterChange(selectedFilter: ReportingDashboardFilterProps): void;
  errorBreakdownProps: ErrorBreakdownProps;
  reportingRateProps: ReportingRateProps;
  oldValues: ReportingDashboardFilterProps;
}

export const ReportingRatesPanel = ({
  onFilterChange,
  errorBreakdownProps,
  reportingRateProps,
  oldValues,
}: ReportingRatesPanelProps) => {
  return (
    <>
      <div className="mt-4 w-full rounded border border-blueGray200 px-2">
        <div className="flex w-full flex-col">
          <div className="grid grid-cols-[auto_264px] ">
            <div>
              <div className="p-6">
                <div className="hidden">
                  <ReportingDashboardFilters onChange={onFilterChange} values={oldValues} />
                </div>
                <ReportingRateChart {...reportingRateProps} />
              </div>
            </div>
            <div className="border-l border-blueGray200 p-6">
              <ReportingErrorBreakdownSection {...errorBreakdownProps} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReportingRatesPanelConnected = () => {
  const [filters, setFilters] = useState<ReportingDashboardFilterProps>(getDefaultFilter());

  const {
    data: errorBreakdown,
    isLoading: errorBreakdownLoading,
    isError: errorBreakdownError,
  } = trpc.reporting.errorsBreakdown.useQuery({
    dateFrom: filters.dateFrom?.toISOString(),
    dateTo: filters.dateTo?.toISOString(),
  });

  const {
    data: reportingRate,
    isLoading: reportingRateLoading,
    isError: reportingRateError,
  } = trpc.eInvoicing.reportingRate.useQuery({
    dateFrom: filters.dateFrom?.toISOString(),
    dateTo: filters.dateTo?.toISOString(),
  });

  return (
    <ReportingRatesPanel
      errorBreakdownProps={{
        selectedView: "type",
        onSelectView() {
          noop;
        },
        data: errorBreakdown,
        loading: errorBreakdownLoading,
        error: errorBreakdownError,
        currentFilter: filters,
      }}
      reportingRateProps={{
        reportingRate,
        reportingRateLoading,
        reportingRateError,
      }}
      onFilterChange={setFilters}
      oldValues={filters}
    />
  );
};
