import { GetStatsResponse } from "@/features/Lookup/ValidationHistory/ValidationHistory.types";

export const mockStatsResponse: GetStatsResponse = {
  stats: [
    {
      date: "2021-12-14T00:00:00Z",
      total_count: 55,
      valid_format_and_checksum_count: 20,
    },
    {
      date: "2021-12-15T00:00:00Z",
      total_count: 0,
      valid_format_and_checksum_count: 0,
    },
    {
      date: "2021-12-16T00:00:00Z",
      total_count: 1,
      valid_format_and_checksum_count: 1,
    },
    {
      date: "2021-12-17T00:00:00Z",
      total_count: 0,
      valid_format_and_checksum_count: 0,
    },
    {
      date: "2021-12-18T00:00:00Z",
      total_count: 201,
      valid_format_and_checksum_count: 111,
    },
    {
      date: "2021-12-19T00:00:00Z",
      total_count: 0,
      valid_format_and_checksum_count: 0,
    },
    {
      date: "2021-12-20T00:00:00Z",
      total_count: 11,
      valid_format_and_checksum_count: 11,
    },
  ],
};
