import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

import { ErrorBreakdownView } from "@/features/E-Invoicing/DashboardPage/components/ReportingErrorBreakdownSection/ReportingErrorBreakdownSection";
import {
  alertsMock,
  reportingRateMock,
  taxBreakdownPerTypeMock,
  totalRevenueMock,
} from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.fixtures";
import { ErrorBreakdownResponse } from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { mockStatsResponse } from "@/features/Lookup/ValidationHistory/GetStatsResponse.fixtures";
import { SubscriptionStats } from "@/hooks/lookup/useSubscriptionStats";
import { IProduct } from "@/lib/product";
import { ErrorCategory } from "@/lib/types/transaction";
import { noop } from "@/lib/utils";

import { LookupValidationsPanel } from "./components/LookupValidationsPanel/LookupValidationsPanel";
import { NexusLiabilitiesPanelConnected } from "./components/NexusLiabilitiesPanel/NexusLiabilitiesPanel";
import { NexusMonitoringPanelConnected } from "./components/NexusMonitoringPanel/NexusMonitoringPanel";
import { ReportingAlertsPanel } from "./components/ReportingAlertsPanel/ReportingAlertsPanel";
import { ReportingRatesPanel } from "./components/ReportingRatesPanel/ReportingRatesPanel";
import { ReportingRevenuesPanel } from "./components/ReportingRevenuesPanel/ReportingRevenuesPanel";
import { ReturnsSummaryPanel } from "./components/ReturnsSummaryPanel/ReturnsSummaryPanel";
import { TaxCollectedPanel } from "./components/TaxCollectedPanel/TaxCollectedPanel";

type CockpitProps = {
  products: IProduct[];
};

const mockErrorBreakdown: ErrorBreakdownResponse = {
  status: "success",
  data: [
    {
      code: ErrorCategory.TaxAuthority,
      count: 87,
      country_code: "PT",
    },
    {
      code: ErrorCategory.Validation,
      count: 2,
      country_code: "PT",
    },
    {
      code: ErrorCategory.General,
      count: 14,
      country_code: "PT",
    },
  ],
};

const mockUnlimitedSubscriptionStats: SubscriptionStats = {
  hasUnlimitedSearchesAmount: true,
  maxSearchesAmount: Infinity,
  searchThresholdState: "safe",
  searchesLeft: Infinity,
  validationThresholdState: "safe",
  validationsLeft: Infinity,
  maxValidationsAmount: Infinity,
  hasUnlimitedValidationsAmount: true,
  selectedApiVersion: 2,
  minApiVersion: 1,
};

export function CockpitDemo({ products }: CockpitProps) {
  const isProductActive = (productSelection: string) =>
    products.find(({ product, isActive }) => productSelection === product && isActive === true);

  const taxActive = isProductActive("tax");
  const reportingActive = isProductActive("reporting");
  const invoicingActive = isProductActive("invoicing");
  const lookupActive = isProductActive("lookup");
  const returnsActive = isProductActive("returns");

  const reportingRateProps = {
    reportingRate: reportingRateMock,
    reportingRateLoading: false,
    reportingRateError: false,
  };

  const errorBreakdownProps = {
    selectedView: "type" as ErrorBreakdownView,
    onSelectView() {
      noop;
    },
    currentFilter: {
      dateFrom: new Date("2021-01-01"),
      dateTo: new Date("2021-01-31"),
      periodKey: "week_to_date",
    },
    data: mockErrorBreakdown,
    error: false,
    loading: false,
  };

  const returnsSummaryProps = {
    total: 40,
    completed: 20,
    in_progress: 10,
    not_started: 10,
    overdue_in_progress: 1,
    overdue_not_started: 0,
  };

  return (
    <>
      <div className="flex justify-between">
        {/* left panel */}
        <div className="flex w-2/3 flex-col border-r border-blueGray200/50 p-6">
          <Typography
            fontWeight="font-medium"
            fontSize="text-lg"
            component="h4"
            color="text-blueGray900"
          >
            <FormattedMessage defaultMessage="Overview" id="9uOFF3" />
            {returnsActive && <ReturnsSummaryPanel data={returnsSummaryProps} />}
            {taxActive && (
              <>
                <NexusMonitoringPanelConnected />
                <NexusLiabilitiesPanelConnected />
              </>
            )}
            {reportingActive && invoicingActive && (
              <>
                <ReportingRatesPanel
                  reportingRateProps={reportingRateProps}
                  errorBreakdownProps={errorBreakdownProps}
                  onFilterChange={() => console.log("filter")}
                  oldValues={errorBreakdownProps.currentFilter}
                />
                <div className="grid grid-cols-2 gap-4">
                  <TaxCollectedPanel
                    selectedView="type"
                    onSelectView={(param) => console.log("filter", param)}
                    dataPerType={taxBreakdownPerTypeMock}
                    loading={false}
                    error={false}
                    onFilterChange={() => console.log("filter changed")}
                  />

                  <ReportingRevenuesPanel
                    loading={false}
                    error={false}
                    values={totalRevenueMock}
                    onFilterChange={(param) => console.log("filter", param)}
                    oldValues={errorBreakdownProps.currentFilter}
                  />
                </div>
              </>
            )}
            {lookupActive && (
              <LookupValidationsPanel
                historyStats={mockStatsResponse.stats}
                subscriptionStats={mockUnlimitedSubscriptionStats}
                isLoadingSubscriptionStats={false}
                isLoadingHistoryStats={false}
              />
            )}
          </Typography>
        </div>

        {/* right panel */}
        <div className="flex w-1/3 flex-col bg-blueGray10 px-6 py-8">
          <>
            <Typography
              fontSize="text-lg"
              fontWeight="font-medium"
              color="text-blueGray900"
              spacing={{ mb: 4 }}
            >
              <FormattedMessage defaultMessage="Alerts" id="9zub+u" />
            </Typography>

            {reportingActive && (
              <ReportingAlertsPanel alerts={alertsMock} alertsLoading={false} alertsError={false} />
            )}
          </>
        </div>
      </div>
    </>
  );
}
