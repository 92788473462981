import { WarningIcon } from "@fonoa/ui-components/icons";
import { Table } from "@fonoa/ui-components/table";
import { Typography } from "@fonoa/ui-components/typography";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { NexusGroup, NexusStatus, StateInfo } from "@/features/Tax/DashboardPage/nexus.types";
import { AnyGroup, useColumns } from "@/features/Tax/DashboardPage/nexusGroupColumns";
import { NexusLiabilityChart } from "@/features/Tax/DashboardPage/Pie";
import { useThresholdOps } from "@/hooks/useThresholdLabels";
import { MonthlyAggregate } from "@/server/trpc/routers/tax/aggregates";
import { USThresholds } from "@/server/trpc/routers/tax/thresholds";
import { trpc } from "@/utils/trpc";

interface LiabilityProps {
  states: StateInfo[];
}

interface NexusGroupsProps {
  groups: NexusGroup[];
  total: number;
}

function formatThresholds(usThresholds?: USThresholds[]): USThresholds[] | undefined {
  return usThresholds?.map((t) => ({
    ...t,
    thresholds: t.thresholds.map((_t) => ({ ..._t, effectiveFrom: new Date(_t.effectiveFrom) })),
  }));
}

function formatAggregates(
  aggregates?: MonthlyAggregate<Date>[]
): MonthlyAggregate<Date>[] | undefined {
  return aggregates?.map((a) => ({
    ...a,
    month: new Date(a.month),
  }));
}

function NexusGroupsTable({ groups, total }: NexusGroupsProps) {
  const allGroups = useMemo(
    () =>
      [
        ...groups.map((g) => ({ ...g, percentage: g.count / total })),
        { count: total },
      ] as AnyGroup[],
    [groups, total]
  );
  const columns = useColumns();
  return (
    <Table
      emptyMessage={
        <div className="flex items-center">
          <Typography>
            <FormattedMessage defaultMessage="Criteria return no results." id="6bO0ZH" />{" "}
            <span className="text-primaryBlue500">
              <FormattedMessage defaultMessage="Please try different criteria." id="CH5cGa" />
            </span>
          </Typography>
        </div>
      }
      loading={false}
      error={false}
      onRowClick={() => undefined}
      columns={columns}
      data={allGroups || []}
      getColumnProps={() => ({
        onClick: () => undefined,
      })}
      getRowProps={() => ({})}
      getCellProps={() => ({})}
      expandableRows={false}
      narrowRows={true}
    />
  );
}

function toStatusGroups(states: StateInfo[]) {
  const groups = new Map<NexusStatus, NexusGroup>();
  states.forEach((s) => {
    const group = groups.get(s.status) || {
      status: s.status,
      count: 0,
    };
    group.count += 1;
    groups.set(s.status, group);
  });
  return [...groups.values()];
}

export function NexusLiabilitiesPanel({ states }: LiabilityProps) {
  const groups = useMemo(
    () => toStatusGroups(states).filter((g) => g.status !== "NO_ACTIVITY"),
    [states]
  );
  const totalGroups = useMemo(() => groups.reduce((t, c) => t + c.count, 0), [groups]);
  return (
    <>
      <div className="mt-4 w-full rounded border border-blueGray200 py-6 px-8">
        <div className="flex items-center justify-start pb-3">
          <WarningIcon className="fill-red500" />
          <Typography component="h5" className="pl-2 font-medium">
            <FormattedMessage defaultMessage="Nexus liability" id="P2S6Ev" />
          </Typography>
        </div>
        <Typography component="h3" fontWeight="font-medium">
          {`${totalGroups} States`}
        </Typography>
        <div className="flex items-center pt-4">
          <div className="flex w-1/3 justify-center">
            <div
              style={{ width: "min(15vw, 175px)", height: "min(15vw, 175px)" }}
              aria-hidden="true" /* Hiding for screen readers as nivo charts is currenty not screen reader friendly */
            >
              <NexusLiabilityChart groups={groups} loading={false} />
            </div>
          </div>
          <div className="w-2/3">
            <NexusGroupsTable groups={groups} total={totalGroups} />
          </div>
        </div>
      </div>
    </>
  );
}

export function NexusLiabilitiesPanelConnected() {
  const tOps = useThresholdOps();

  const query = useMemo(() => ({ country: "us" }), []);

  const { data: aggregates } = trpc.tax.getMonthlyAggregates.useQuery(query, {
    select: formatAggregates,
  });
  const { data: registrations } = trpc.tax.getRegistrations.useQuery();
  const { data: thresholds } = trpc.tax.getUsThresholds.useQuery(undefined, {
    select: formatThresholds,
  });

  const allStates: StateInfo[] = useMemo(() => {
    if (!aggregates || !registrations || !thresholds) return [];

    const isRegisteredInRegion = (region: string | undefined) => {
      return registrations.some(
        (reg) => reg.region === region && (!reg.end_date || new Date(reg.end_date) >= new Date())
      );
    };

    const states = thresholds.map((t) => t.fips);
    return states.map((fips) => {
      const threshold = tOps.findThreshold(thresholds, fips);
      const registered = isRegisteredInRegion(fips);
      const months = aggregates.filter((a) => a.region === fips);

      const [cMonths, ...pastPeriods] = tOps.groupByEvaluationPeriod(months, threshold);
      const revenue = cMonths.map((a) => a.revenue?.total || 0).reduce((t, c) => t + c, 0);
      const transactions = cMonths.map((a) => a.count?.total || 0).reduce((t, c) => t + c, 0);

      function findStatus(amount: number, limit?: number): NexusStatus {
        if (registered) return "REGISTERED";

        const fraction = amount / (limit ?? Number.POSITIVE_INFINITY);
        if (fraction > 1) return "THRESHOLD_EXCEEDED";
        if (fraction > 0.75) return "APPROACHING_THRESHOLD";
        if (fraction > 0) return "TO_MONITOR";
        return "NO_ACTIVITY";
      }

      function findOverallStatus(rStatus: NexusStatus, cStatus: NexusStatus): NexusStatus {
        if (registered) return "REGISTERED";
        const previouslyExceeded = pastPeriods?.some((pMonths) => {
          const revenue = pMonths.map((a) => a.revenue?.total || 0).reduce((t, c) => t + c, 0);
          const transactions = pMonths.map((a) => a.count?.total || 0).reduce((t, c) => t + c, 0);
          return (
            revenue > (threshold?.revenueLimit ?? Number.POSITIVE_INFINITY) ||
            transactions > (threshold?.transactionLimit ?? Number.POSITIVE_INFINITY)
          );
        });
        if (previouslyExceeded) return "THRESHOLD_EXCEEDED";
        const statuses = [rStatus, cStatus];
        if (statuses.includes("THRESHOLD_EXCEEDED")) return "THRESHOLD_EXCEEDED";
        if (statuses.includes("APPROACHING_THRESHOLD")) return "APPROACHING_THRESHOLD";
        if (statuses.includes("TO_MONITOR")) return "TO_MONITOR";
        return "NO_ACTIVITY";
      }

      const revenueStatus = findStatus(revenue, threshold?.revenueLimit);
      const transactionStatus = findStatus(transactions, threshold?.transactionLimit);
      return {
        fips,
        status: findOverallStatus(revenueStatus, transactionStatus),
        revenue,
        thresholdDescription: tOps.getDescription(threshold),
        revenueThreshold: threshold?.revenueLimit,
        revenueStatus,
        transactions,
        transactionThreshold: threshold?.transactionLimit,
        transactionStatus,
      } as StateInfo;
    });
  }, [tOps, aggregates, registrations, thresholds]);

  return <NexusLiabilitiesPanel states={allStates} />;
}
