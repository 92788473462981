import { ColumnAlign, Table, TableColumn } from "@fonoa/ui-components/table";
import { Typography } from "@fonoa/ui-components/typography";
import { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import BigToggle from "@/components/Form/BigToggle";
import {
  getDefaultFilter,
  ReportingDashboardFilterProps,
} from "@/features/E-Invoicing/DashboardPage/components/ReportingDashboardFilters/ReportingDashboardFilters";
import {
  TaxBreakdownPerRateItem,
  TaxBreakdownPerRateResponse,
  TaxBreakdownPerRegimeItem,
  TaxBreakdownPerRegimeResponse,
} from "@/features/E-Invoicing/DashboardPage/EInvoicingDashboardPage.types";
import { trpc } from "@/utils/trpc";

export type TaxBreakdownView = "rate" | "type";

export interface TotalTaxCollectedSectionProps {
  onFilterChange(selectedFilter: ReportingDashboardFilterProps): void;
  selectedView: TaxBreakdownView;
  onSelectView(view: TaxBreakdownView): void;
  dataPerRate?: TaxBreakdownPerRateResponse;
  dataPerType?: TaxBreakdownPerRegimeResponse;
  loading: boolean;
  error: boolean;
}

export const TaxCollectedPanel = ({
  selectedView,
  onSelectView,
  loading,
  error,
  dataPerType,
  dataPerRate,
}: TotalTaxCollectedSectionProps) => {
  return (
    <>
      <div className="mt-4 w-full rounded border border-blueGray200 px-8 py-5">
        <div className="mb-1 flex">
          <Typography
            variant="h5"
            fontWeight="font-medium"
            color="text-blueGray900"
            spacing={{ mr: 2 }}
          >
            <FormattedMessage defaultMessage="Tax collected" id="dHiEFz" />
          </Typography>
        </div>
        <div className="mt-5 mb-4">
          <Typography variant="h6" color="text-blueGray900" spacing={{ mb: 3 }}>
            <FormattedMessage defaultMessage="Breakdown by" id="VKqy57" />
          </Typography>
          <BigToggle
            selectedItemKey={selectedView}
            items={[
              { key: "type", label: "Tax type", onClick: () => onSelectView("type") },
              { key: "rate", label: "Tax rate", onClick: () => onSelectView("rate") },
            ]}
          />
        </div>
        <div>
          {selectedView === "type" ? (
            <TaxBreakdownTable
              isLoading={loading}
              error={error}
              columns={taxesPerTypeColumns}
              data={dataPerType?.data}
            />
          ) : (
            <TaxBreakdownTable
              isLoading={loading}
              error={error}
              columns={taxesPerRateColumns}
              data={dataPerRate?.data}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const TaxCollectedPanelConnected = () => {
  const [filters, setFilters] = useState<ReportingDashboardFilterProps>(getDefaultFilter());

  const [taxView, setTaxView] = useState<TaxBreakdownView>("type");
  const isTaxBreakdownPerType = taxView === "type";

  const {
    data: taxesBreakdownPerRegime,
    isLoading: taxesBreakdownPerRegimeLoading,
    isError: taxesBreakdownPeRegimeError,
  } = trpc.eInvoicing.taxBreakdownPerRegime.useQuery({
    dateFrom: filters.dateFrom?.toISOString(),
    dateTo: filters.dateTo?.toISOString(),
  });

  const {
    data: taxesBreakdownPerRate,
    isLoading: taxesBreakdownPerRateLoading,
    isError: taxesBreakdownPerRateError,
  } = trpc.eInvoicing.taxBreakdownPerRate.useQuery(
    {
      dateFrom: filters.dateFrom?.toISOString(),
      dateTo: filters.dateTo?.toISOString(),
    },
    { enabled: !isTaxBreakdownPerType }
  );

  return (
    <TaxCollectedPanel
      selectedView={taxView}
      onSelectView={setTaxView}
      loading={
        isTaxBreakdownPerType ? taxesBreakdownPerRegimeLoading : taxesBreakdownPerRateLoading
      }
      error={isTaxBreakdownPerType ? taxesBreakdownPeRegimeError : taxesBreakdownPerRateError}
      dataPerRate={taxesBreakdownPerRate}
      dataPerType={taxesBreakdownPerRegime}
      onFilterChange={setFilters}
    />
  );
};

const TaxBreakdownTable = <T extends { total_amount: number }>({
  isLoading,
  error,
  columns,
  data,
}: {
  isLoading: boolean;
  error: boolean;
  columns: TableColumn<T>[];
  data?: T[];
}) => {
  return (
    <Table
      columns={columns}
      data={
        data
          ?.filter((item) => item.total_amount > 0)
          .sort((a, b) => b.total_amount - a.total_amount) || []
      }
      error={error}
      loading={isLoading}
      narrowRows
      emptyHeightAsRow
      emptyImage={<></>}
      loadingRowLimit={2}
      customErrorComponent={
        <Typography variant="small" color="text-blueGray900" spacing={{ mt: 2.5 }}>
          <FormattedMessage
            defaultMessage="Something went wrong loading the data. Please try again later or contact us."
            id="31/x0B"
          />
        </Typography>
      }
    />
  );
};

const taxesPerTypeColumns: TableColumn<TaxBreakdownPerRegimeItem>[] = [
  {
    Header: "Type",
    accessor: "regime",
    disableSortBy: true,
    noWrap: true,
    headClassName: "text-xs",
    className: "text-xs",
    Cell: ({ value }) => (
      <>{value ? value : <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />}</>
    ),
  },
  {
    Header: "Amount",
    accessor: "total_amount",
    disableSortBy: true,
    noWrap: true,
    align: ColumnAlign.RIGHT,
    headClassName: "text-xs",
    className: "text-xs",
    Cell: ({ value, row }) => (
      <>
        {row.original.currency_code.toUpperCase()}{" "}
        <FormattedNumber value={value as number} maximumFractionDigits={2} />
      </>
    ),
  },
];

const taxesPerRateColumns: TableColumn<TaxBreakdownPerRateItem>[] = [
  {
    Header: "Type",
    accessor: "rate",
    disableSortBy: true,
    noWrap: true,
    headClassName: "text-xs",
    className: "text-xs",
    Cell: ({ value }) => (
      <>
        <FormattedNumber
          value={value / 100}
          style="percent"
          maximumFractionDigits={2}
          minimumFractionDigits={0}
        />
      </>
    ),
  },
  {
    Header: "Amount",
    accessor: "total_amount",
    disableSortBy: true,
    noWrap: true,
    align: ColumnAlign.RIGHT,
    headClassName: "text-xs",
    className: "text-xs",
    Cell: ({ value, row }) => (
      <>
        {row.original.currency_code.toUpperCase()}{" "}
        <FormattedNumber value={value as number} maximumFractionDigits={2} />
      </>
    ),
  },
];
