import {
  CountryExtraOutput,
  LookupCountryData,
  LookupSupportedCountries,
  ReverseLookupCountryData,
  stubCountries,
} from "@fonoa/data-access/countries";
import { SelectOption } from "@fonoa/ui-components/select";
import { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";

import { ValidationSource, ValidationSourceRequest } from "@/api/lookup/interfaces";
import { Option } from "@/lib/types";

export const getCountryOptions = (countries: { [id: string]: LookupCountryData }) =>
  Object.values(countries)
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .filter(({ country_iso }) => country_iso !== "el") // Greece has duplication 'gr' and 'el'
    .map(({ country_iso, country_name }) => ({ value: country_iso, label: country_name }));

export const getAllCountryOptions = (
  countries: LookupSupportedCountries | undefined
): SelectOption[] => {
  if (!countries) {
    return [];
  }

  const availableCountries: SelectOption[] = Object.values(countries.availableCountries)
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .filter(({ country_iso }) => country_iso !== "el") // Greece has duplication 'gr' and 'el'
    .map(({ country_iso, country_name }) => ({ value: country_iso, label: country_name }));

  const unavailableCountries: SelectOption[] = Object.values(countries.allCountries)
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .filter(({ country_iso }) => country_iso !== "el") // Greece has duplication 'gr' and 'el'
    .filter(({ country_iso }) => !countries.availableCountries[country_iso])
    .map(({ country_iso, country_name }) => ({
      value: country_iso,
      label: country_name,
      disabled: true,
    }));

  return [...availableCountries, ...unavailableCountries];
};

export const getReverseLookupCountryOptions = (countries: {
  [id: string]: ReverseLookupCountryData;
}) =>
  Object.values(countries)
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .filter(({ country_iso_code }) => country_iso_code !== "el") // Greece has duplication 'gr' and 'el'
    .map(({ country_iso_code, country_name }) => ({
      value: country_iso_code,
      label: country_name,
    }));

export function getTurkeyProvinceParameterOptions(): Option[] | undefined {
  const country = stubCountries.find(({ id }) => isTurkey(id));
  const parameterField = country?.form?.fields?.find((field) => field.name === "turkey_province");
  return parameterField?.choices?.map((c) => ({ label: c.name, value: c.id }));
}

export function getTurkeyTaxOfficeParameterOptions(province?: string): Option[] | undefined {
  const country = stubCountries.find(({ id }) => isTurkey(id));
  const parameterField = country?.form?.fields?.find((field) => field.name === "turkey_tax_office");
  return parameterField?.choices
    ?.filter(({ id }) => (province ? id.startsWith(province) : true))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((c) => ({ label: c.name, value: c.id }));
}

export function getIndiaStateNameOptions(): Option[] | undefined {
  const country = stubCountries.find(({ id }) => isIndia(id));
  const parameterField = country?.form?.fields?.find((field) => field.name === "india_state_name");
  return parameterField?.choices?.map((c) => ({ label: c.name, value: c.id }));
}

export const TOS_URLS = {
  ADMIN: "https://www.fonoa.com/legal/privacy-notice",
} as const;

type TOSKeys = keyof typeof TOS_URLS;
export type TOSValueType = typeof TOS_URLS[TOSKeys];

interface InvalidTinCountInput {
  totalCount: number;
  validFormatAndChecksumCount: number;
}

export function getInvalidTinCount({
  totalCount,
  validFormatAndChecksumCount,
}: InvalidTinCountInput): number {
  return totalCount - validFormatAndChecksumCount;
}

export function useValidationSourceOptions(
  countryData: LookupCountryData | undefined
): { value: ValidationSourceRequest; label: string }[] {
  const intl = useIntl();
  if (countryData?.vies_implementation && countryData?.local_implementation) {
    return [
      { value: undefined, label: intl.formatMessage({ defaultMessage: "Default", id: "lKv8ex" }) },
      { value: "vies", label: intl.formatMessage({ defaultMessage: "VIES only", id: "f89+0t" }) },
      {
        value: "local",
        label: intl.formatMessage({ defaultMessage: "Local Databases only", id: "/ZMNuQ" }),
      },
    ];
  }
  return [];
}

export const lookupPrettyValidationSourceNames = defineMessages<ValidationSource>({
  vies: {
    defaultMessage: "VIES",
    id: "hHHA8V",
  },
  local: {
    defaultMessage: "Local DB",
    id: "SmSRC+",
  },
  vies_and_local: {
    defaultMessage: "VIES, Local DB",
    id: "o+swMH",
  },
  not_available: {
    defaultMessage: "Not available",
    id: "cPhKTw",
  },
  none: {
    defaultMessage: "Not available",
    id: "cPhKTw",
  },
});

export const useGetLookupValidationSourceName = () => {
  const intl = useIntl();

  return useCallback(
    (source?: ValidationSource) => {
      if (!source) return null;
      return intl.formatMessage(lookupPrettyValidationSourceNames[source]);
    },
    [intl]
  );
};

export type LookupAdditionalParameter =
  | "turkey_tax_office"
  | "name"
  | "first_name"
  | "last_name"
  | "zip_code"
  | "date"
  | "canada_bc_pst"
  | "india_state_name";

export function getCountryAdditionalParameters(
  countryData: LookupCountryData | undefined
): string[] {
  return countryData?.additional_parameters.map((value) => value.name) || [];
}

export function getAdditionalParametersDefault<ParamValueType>(
  countries: Record<string, LookupCountryData>,
  defaultValueCreator: (param: string) => ParamValueType
): Record<LookupAdditionalParameter, ParamValueType> {
  return Object.values(countries).reduce(
    (parameters, nextCountry) => ({
      ...parameters,
      ...getCountryAdditionalParameters(nextCountry).reduce(
        (prev, next) => ({ ...prev, [next]: defaultValueCreator(next) }),
        {}
      ),
    }),
    {} as Record<LookupAdditionalParameter, ParamValueType>
  );
}

const isAvailableForValidationSource = (
  selectedValidationSource: ValidationSourceRequest,
  extraOutput: CountryExtraOutput
): boolean =>
  !extraOutput.limited_to_validation_source ||
  !selectedValidationSource ||
  extraOutput.limited_to_validation_source.toString() === selectedValidationSource.toString();

export const isExtraOutputSupported = (
  countryData: LookupCountryData | undefined,
  fieldName: string,
  selectedValidationSource: ValidationSourceRequest
): boolean => {
  if (selectedValidationSource === "vies" && !countryData?.vies_implementation) return false;
  if (selectedValidationSource === "local" && !countryData?.local_implementation) return false;
  const extraOutput = countryData?.additional_response_data?.find(({ name }) => name === fieldName);
  return !extraOutput
    ? false
    : isAvailableForValidationSource(selectedValidationSource, extraOutput);
};

export const isNameFuzzyMatchingSupported = (
  countryData: LookupCountryData | undefined,
  selectedValidationSource: ValidationSourceRequest
): boolean => isExtraOutputSupported(countryData, "name", selectedValidationSource);

export const isAddressFuzzyMatchingSupported = (
  countryData: LookupCountryData | undefined,
  selectedValidationSource: ValidationSourceRequest
): boolean => isExtraOutputSupported(countryData, "address", selectedValidationSource);

export const isCanada = (countryIsoCode: string): boolean => countryIsoCode === "ca";
export const isIndia = (countryIsoCode: string): boolean => countryIsoCode === "in";
export const isUnitedStates = (countryIsoCode: string): boolean => countryIsoCode === "us";
export const isTurkey = (countryIsoCode: string): boolean => countryIsoCode === "tr";
