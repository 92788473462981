import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { UploadCSVQueryKeys } from "@/features/Lookup/CsvUploadValidation/CsvUploadValidation.queries";
import { Response } from "@/server/middlewares/withResponse";
import { ApiErrorResponse, ApiResponse } from "@/server/types";

import {
  GetStatsResponse,
  GetTinsResponse,
  ValidationHistoryStatsFilterValueProps,
  ValidationHistoryTinsFilterValueProps,
} from "./ValidationHistory.types";

interface GetStatsProps {
  variables?: ValidationHistoryStatsFilterValueProps;
}

export async function getStatsResults({ variables }: GetStatsProps) {
  const res = await axios.get("/api/lookup/get-stats-validations", {
    params: { variables },
  });

  return res.data;
}

export const useValidationHistoryStatsData = <
  ReturnType extends Record<string, unknown> = Response<GetStatsResponse>
>({
  variables,
  options,
}: {
  variables?: ValidationHistoryStatsFilterValueProps;
  options?: Omit<
    UseQueryOptions<ApiResponse<GetStatsResponse>, unknown, ReturnType>,
    "queryKey" | "queryFn"
  >;
}) => {
  return useQuery(
    ["getValidationHistoryStatsData", variables, options],
    () => getStatsResults({ variables }),
    options
  );
};

export type GetTinsVariables = Omit<ValidationHistoryTinsFilterValueProps, "country"> & {
  country?: string;
};

interface GetTinsProps {
  variables?: GetTinsVariables;
}

export async function getTinsResults({ variables }: GetTinsProps) {
  const res = await axios.get<Response<GetTinsResponse>>("/api/lookup/get-validations", {
    params: { variables },
  });
  return res.data;
}

export const useValidationHistoryTinsData = <
  ReturnType extends Record<string, unknown> = Response<GetTinsResponse>
>({
  variables,
  options,
}: {
  variables?: Omit<ValidationHistoryTinsFilterValueProps, "country"> & { country?: string };
  options?: Omit<
    UseQueryOptions<Response<GetTinsResponse>, unknown, ReturnType>,
    "queryKey" | "queryFn"
  >;
}) => {
  return useQuery<Response<GetTinsResponse>, unknown, ReturnType>(
    ["getValidationHistoryTinsData", variables, options],
    () => getTinsResults({ variables }),
    options
  );
};

export function useRenameBatch() {
  const queryClient = useQueryClient();

  return useMutation<ApiResponse<{ new_batch_name: string }>, ApiErrorResponse, RenameBatchProps>(
    (variables) => renameBatch(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([UploadCSVQueryKeys.batchValidations]);
      },
    }
  );
}

export interface RenameBatchProps {
  batchId: string;
  newBatchName: string;
}

export async function renameBatch({ batchId, newBatchName }: RenameBatchProps) {
  const res = await axios.post<ApiResponse<{ new_batch_name: string }>>(
    "/api/lookup/patch-batch-validation-name",
    {
      batchId,
      newBatchName,
    }
  );

  return res.data;
}
