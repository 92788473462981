import { subject } from "@casl/ability";
import { Typography } from "@fonoa/ui-components/typography";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import InviteUserCard from "@/components/Dashboard/InviteUserCard";
import ProductCards from "@/components/Dashboard/ProductCards";
import SidebarLayout from "@/components/Layouts/SidebarLayout";
import { CockpitDemo } from "@/features/Cockpit/CockpitDemo";
import { useTenant, withAuthentication } from "@/hooks/auth";
import { useFeatureFlags } from "@/hooks/featureFlags/FeatureFlags";
import { useAbility } from "@/hooks/useAbility";
import { useAreProductsActive, useAreSubproductsActive } from "@/hooks/useIsProductActive";

function HomePage() {
  const router = useRouter();
  const ability = useAbility();

  const { cockpitDemo: isCockpitDemoEnabled } = useFeatureFlags();

  const onClickInvite = () => {
    router.push("/settings/team");
  };

  const { products } = useAreProductsActive();
  const { subproducts } = useAreSubproductsActive();

  const { data: tenant } = useTenant();

  return (
    <>
      {isCockpitDemoEnabled ? (
        <SidebarLayout windowTitle="Fonoa Dashboard" withPadding={false}>
          <CockpitDemo products={products} />
        </SidebarLayout>
      ) : (
        <SidebarLayout windowTitle="Fonoa Dashboard">
          {ability.can("invite", subject("users", { product: "dashboard" })) && (
            <InviteUserCard onClickInviteUsers={onClickInvite} />
          )}

          <Typography
            component="h3"
            spacing={{ mt: 8 }}
            fontWeight="font-medium"
            dataAttributes={{ cy: "active-products-message" }}
          >
            <FormattedMessage defaultMessage="Fonoa Products" id="APwadj" />
          </Typography>
          <ProductCards
            products={products}
            subproducts={subproducts}
            onOpenProduct={router.push}
            isLegacyReportingTenant={tenant?.isReportingLegacyTenant || false}
          />
        </SidebarLayout>
      )}
    </>
  );
}

export default withAuthentication(HomePage);
