import { Select } from "@fonoa/ui-components/select";
import { Typography } from "@fonoa/ui-components/typography";
import { noop } from "@fonoa/ui-components/utils";
import { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { mapTagToRange } from "@/components/Utils/Date";
import { DateRange, useDateRangeFilterOptions } from "@/components/Utils/SelectOptions";
import { parseChartData } from "@/features/Lookup/ValidationHistory/components/ValidationHistorySummary/ValidationHistorySummary.utils";
import { useDateRangeQueryParam } from "@/features/Lookup/ValidationHistory/useDateRangeQueryParam";
import { useValidationHistoryStatsData } from "@/features/Lookup/ValidationHistory/ValidationHistory.api";

import LineChart from "./LineChart";

export function LookupValidationsPanel({
  historyStats,
  isLoadingHistoryStats = false,
  onChartMouseEnter = noop,
  onChartMouseLeave = noop,
  chartProps = {},
  setIsDataAvailable = noop, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) {
  const { dateRange, updateDateRange } = useDateRangeQueryParam();

  const dateRangeItems = useDateRangeFilterOptions({
    exclude: ["today", "custom"],
  });

  const chartData = useMemo(
    () => parseChartData(historyStats, dateRange),
    [historyStats, dateRange]
  );

  useEffect(() => {
    if (chartData) {
      const isEmpty = chartData.every((d) => d.data.length === 0);
      setIsDataAvailable(!isEmpty);
    }
  }, [chartData, setIsDataAvailable]);

  return (
    <div
      className="mt-4 w-full rounded border border-blueGray200 px-8 py-5"
      onMouseEnter={onChartMouseEnter}
      onMouseLeave={onChartMouseLeave}
    >
      <div className="mb-6 flex flex-col justify-between md:flex-row">
        <div className="flex items-center">
          <Typography
            variant="h5"
            fontWeight="font-medium"
            color="text-blueGray900"
            spacing={{ mr: 2 }}
          >
            <FormattedMessage defaultMessage="TIN Validations" id="62+A05" />
          </Typography>
        </div>
        {!isLoadingHistoryStats && chartData?.length !== 0 ? (
          <div className="self-end">
            <Select
              className="rounded"
              dataAttributes={{ cy: "date-range" }}
              options={dateRangeItems}
              value={dateRange || ""}
              onChange={(v) => updateDateRange(v as DateRange)}
              width="w-32"
              placeholder={<FormattedMessage defaultMessage="Date range" id="tygEJX" />}
              size="MEDIUM"
              labelClassName="text-blueGray600 font-medium"
              biColorList={false}
            />
          </div>
        ) : null}
      </div>
      <div className="mt-4">
        <LineChart
          loading={isLoadingHistoryStats}
          data={chartData}
          height={200}
          dateRange={dateRange}
          {...chartProps}
        />
      </div>
      <div className="flex justify-end pt-4">
        <div className="flex">
          <div className="ml-8 mt-1 h-2 w-2 rounded-full bg-green300" />
          <Typography component="h6" color="text-blueGray600" spacing={{ ml: 2 }}>
            <FormattedMessage defaultMessage="Valid" id="BN5yXZ" />
          </Typography>

          <div className="flex">
            <div className="ml-8 mt-1 h-2 w-2 rounded-full bg-red200" />
            <Typography component="h6" color="text-blueGray600" spacing={{ ml: 2 }}>
              <FormattedMessage defaultMessage="Invalid" id="89T/ze" />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export const LookupValidationsPanelConnected = () => {
  const { dateRange } = useDateRangeQueryParam();

  const { data: historyStats, isLoading: isLoadingHistoryStats } = useValidationHistoryStatsData({
    variables: { date_from: mapTagToRange(dateRange)?.from },
    options: { select: (d) => d.data },
  });

  return (
    <LookupValidationsPanel
      historyStats={historyStats?.stats ?? []}
      isLoadingHistoryStats={isLoadingHistoryStats}
      onChartMouseEnter={noop}
      onChartMouseLeave={noop}
      chartProps={null}
      setIsDataAvailable={noop}
    />
  );
};
