import { Router, useRouter } from "next/router";
import { z } from "zod";

import { DatesRange, PrettyDateRange } from "@/components/Utils/Date";
import { RouteValueType } from "@/lib/routes";

type UrlObject = Exclude<Parameters<Router["push"]>[0], string>;

export const coerceToOptionalArrayOfStrings = z
  .union([z.string(), z.array(z.string())])
  .transform((rel) => (Array.isArray(rel) ? rel : [rel]))
  .optional();

export const coerceToString = z
  .union([z.string(), z.array(z.string())])
  .transform((rel) => rel.toString());

export const coerceToNumber = z
  .union([z.number(), z.array(z.number())])
  .transform((rel) => Number(rel));

export const coerceToOptionalString = coerceToString.optional();
export const coerceToOptionalNumber = coerceToNumber.optional();

export const useTypedRouter = <
  ZodSchema extends z.Schema,
  PossibleRoutes extends Extract<RouteValueType, string>
>(
  schema: ZodSchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _: PossibleRoutes[]
) => {
  type FilterSchema = z.infer<ZodSchema>;
  const { query, ...router } = useRouter();

  const setFilters = (filters: FilterSchema) => {
    router.push({
      query: cleanupQueryParams({
        ...query,
        ...filters,
      }),
    });
  };

  const prepareUrl = (route: PossibleRoutes, filters?: FilterSchema): UrlObject => {
    return { pathname: route, query: filters };
  };

  return {
    query: schema.parse(query) as z.infer<ZodSchema>,
    ...router,
    setFilters,
    prepareUrl,
  };
};

export const cleanupQueryParams = (
  query: Record<string, string | number | boolean | null | undefined | string[]>
) => {
  const cleanedQuery = { ...query };

  for (const filter in cleanedQuery) {
    if (!cleanedQuery[filter]) {
      delete cleanedQuery[filter];
    }
  }

  return cleanedQuery;
};

export function booleanFilter<T>(argument: T | undefined | boolean | null | 0 | ""): argument is T {
  return Boolean(argument);
}

export const dateFilterFromDateRanges = (valuesWithDateRange?: DatesRange | null) => {
  if (!valuesWithDateRange) {
    return { date_from: undefined, date_to: undefined };
  }

  return { date_from: valuesWithDateRange.from, date_to: valuesWithDateRange.to };
};

export const transactionDateFilterFromDateRanges = (valuesWithDateRange?: DatesRange | null) => {
  if (!valuesWithDateRange) {
    return { transaction_date_from: undefined, transaction_date_to: undefined };
  }

  return {
    transaction_date_from: valuesWithDateRange.from,
    transaction_date_to: valuesWithDateRange.to,
  };
};

export const reportingDateFilterFromDateRanges = (valuesWithDateRange?: DatesRange | null) => {
  if (!valuesWithDateRange) {
    return { reporting_date_from: undefined, reporting_date_to: undefined };
  }

  return {
    reporting_date_from: valuesWithDateRange.from,
    reporting_date_to: valuesWithDateRange.to,
  };
};

export const lastUpdatedDateFilterFromDateRanges = (valuesWithDateRange?: DatesRange | null) => {
  if (!valuesWithDateRange) {
    return { updated_at_from: undefined, updated_at_to: undefined };
  }

  return {
    updated_at_from: valuesWithDateRange.from,
    updated_at_to: valuesWithDateRange.to,
  };
};

export const transactionDateRangesFromDateFilter = (dateFilter: DateFilter): DatesRange | null => {
  if (dateFilter.date_from && dateFilter.date_to) {
    return { from: dateFilter.date_from, to: dateFilter.date_to };
  }

  return null;
};

type DateFilter = {
  date_from?: string | undefined;
  date_to?: string | undefined;
};

export const dateRangesFromDateFilter = (dateFilter: DateFilter): DatesRange | null => {
  if (dateFilter.date_from && dateFilter.date_to) {
    return { from: dateFilter.date_from, to: dateFilter.date_to };
  }

  return null;
};

export const filterItemPrettyDatesRange = (value: DatesRange) => (
  <PrettyDateRange
    range={{
      from: value.from,
      to: value.to,
    }}
  />
);
