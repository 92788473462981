import { LineProps, ResponsiveLine } from "@nivo/line";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { DateRange } from "@/components/Utils/SelectOptions";
import { parseChartData } from "@/features/Lookup/ValidationHistory/components/ValidationHistorySummary/ValidationHistorySummary.utils";
import { mockStatsResponse } from "@/features/Lookup/ValidationHistory/GetStatsResponse.fixtures";

interface LinePoint {
  x: number | string;
  y: number;
}

interface LineElement {
  id: string;
  data: LinePoint[];
}

export interface LineChartProps extends LineProps {
  data: LineElement[];
  height: number;
  loading: boolean;
  dateRange?: DateRange;
  alternativeEmptyChart?: boolean;
}

const sharedChartProps: Partial<LineProps> = {
  margin: { top: 5, right: 20, bottom: 34, left: 40 },
  xScale: { type: "point" },
  yScale: { type: "linear", min: "auto" },
  lineWidth: 2,
  pointSize: 6,
  pointColor: "#DCE6F9",
  pointBorderWidth: 1,
  pointBorderColor: { from: "serieColor" },
  enableGridX: false,
  gridYValues: 4,
  tooltip: () => null,
  theme: {
    tooltip: {
      container: { padding: 0, minHeight: "min-content" },
    },
    fontSize: 10,
    axis: {
      ticks: {
        text: {
          fill: "#96AAB9",
        },
      },
    },
    grid: {
      line: {
        stroke: "#96AAB9",
        strokeWidth: 1,
        strokeDasharray: "3 3",
      },
    },
  },
};

const loadingRenderTickBottom: LineProps["axisBottom"] = {
  renderTick: ({ opacity, x, y }) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <rect
          width="32"
          height="16"
          x="8"
          y="8"
          rx="0"
          ry="0"
          fill="#E3E8EC"
          strokeWidth="0"
          stroke="#E3E8EC"
        />
      </g>
    );
  },
};

const loadingRenderTickLeft: LineProps["axisLeft"] = {
  tickValues: 3,
  renderTick: ({ opacity, x, y }) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <rect
          width="14"
          height="16"
          x="-19.5"
          y="-8"
          rx="0"
          ry="0"
          fill="#E3E8EC"
          strokeWidth="0"
          stroke="#E3E8EC"
        />
      </g>
    );
  },
};

function LineChartEmpty(props: Omit<LineChartProps, "data" | "loading" | "height">) {
  return (
    <>
      <ResponsiveLine
        data={parseChartData(mockStatsResponse.stats)}
        colors={["transparent"]}
        axisLeft={{ tickValues: 3 }}
        axisBottom={{
          format: (value) => {
            switch (props.dateRange) {
              case "last_7_days":
              case "last_14_days":
                return moment(value).format("MMM D");
              case "last_30_days":
                return moment(value).format("D");
              case "last_3_months":
              case "last_6_months":
              case "last_12_months":
                return value;
              default:
                return value;
            }
          },
          tickSize: 12,
        }}
        {...sharedChartProps}
        {...props}
      />
      <div className="m-auto mt-10 flex w-64 items-center justify-center text-center">
        <span className="-mt-7 text-sm font-normal text-blueGray900">
          <FormattedMessage defaultMessage="No data available for this period" id="OlW5Z8" />
        </span>
      </div>
    </>
  );
}

function LineChartLoading(props: Omit<LineChartProps, "data" | "loading" | "height">) {
  return (
    <ResponsiveLine
      data={parseChartData(mockStatsResponse.stats)}
      colors={["#D7DEE3", "#D7DEE3"]}
      axisBottom={loadingRenderTickBottom}
      axisLeft={loadingRenderTickLeft}
      {...sharedChartProps}
      {...props}
    />
  );
}

export default function LineChart({
  height,
  data,
  loading,
  dateRange,
  alternativeEmptyChart,
  ...props
}: LineChartProps) {
  const isChartEmpty = data?.every((d) => d.data.length === 0);

  const getElementPosition = (value: string) => {
    const every5th: LinePoint[] = [];
    const validData = data?.[0]?.data ?? [];

    for (let i = 0; i < validData.length; i = i + 5) {
      every5th.push(validData[i]);
    }

    const isFirst = every5th[0]?.x === new Date(value).toISOString();
    const isLast = every5th[every5th.length - 1]?.x === new Date(value).toISOString();
    return {
      isFirst,
      isLast,
      isEvery5th: every5th.some((d) => d?.x === new Date(value).toISOString()),
    };
  };

  return (
    <div
      style={{ height: height + "px" }}
      aria-hidden="true" /* Hiding for screen readers as nivo charts is currenty not screen reader friendly */
    >
      {loading && <LineChartLoading {...props} />}
      {!loading && isChartEmpty && !alternativeEmptyChart ? (
        <LineChartEmpty dateRange={dateRange} {...props} />
      ) : null}

      {!loading && data?.length ? (
        <ResponsiveLine
          data={data}
          colors={["#F29888", "#84D0A2"]}
          axisLeft={{ tickValues: 3 }}
          useMesh={true}
          gridYValues={3}
          pointColor="transparent"
          yScale={{ type: "linear" }}
          axisBottom={{
            ...(dateRange === "last_30_days" ? { tickValues: 30 } : {}),
            format: (value) => {
              switch (dateRange) {
                case "last_7_days":
                case "last_14_days":
                  return moment(value).format("MMM D");
                case "last_30_days": {
                  const { isEvery5th, isFirst, isLast } = getElementPosition(value);
                  if (!isEvery5th) return "";
                  if (isFirst) return moment(value).format("MMM D");
                  if (isLast) return moment(value).format("D MMM");
                  return moment(value).format("D");
                }
                case "last_3_months":
                  return moment(value).format("D MMM");
                case "last_6_months":
                case "last_12_months":
                  return moment(value).format("MMM");
                default:
                  return "";
              }
            },
          }}
          {...sharedChartProps}
          {...props}
        />
      ) : null}
    </div>
  );
}
